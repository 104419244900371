















































import Vue from 'vue';
import gql from 'graphql-tag';
import prerenderRoutesItems from '../prerenderRoutesItems.json';

const baseRoutes: string[] = [
  '/',
  '/tools/slaythespire/',
  '/tools/slaythespire/devlog/',
  '/tools/slaythespire/mods/',
  '/tools/slaythespire/tierlist/cards/ironclad/',
  '/tools/slaythespire/tierlist/cards/silent/',
  '/tools/slaythespire/tierlist/cards/defect/',
  '/tools/slaythespire/tierlist/cards/watcher/',
  '/tools/slaythespire/tierlist/cards/colorless/',
  '/tools/slaythespire/tierlist/relics/ironclad/',
  '/tools/slaythespire/tierlist/relics/silent/',
  '/tools/slaythespire/tierlist/relics/defect/',
  '/tools/slaythespire/tierlist/relics/watcher/',
  '/tools/slaythespire/upload/',
];

export default Vue.extend({
  name: 'frontendData',
  data() {
    return {
      baseRoutes: baseRoutes as string[],
      allData: {} as {cards: [], relics: []},
      prerenderCurrentRoutes: prerenderRoutesItems as string[],
    };
  },
  computed: {
    completeDataOutput(): string {
      if (this.isObjEmpty(this.allData)) {
        return '';
      }
      const removeTypenameCards = this.allData.cards.map((card: {}) => this.removeTypeNameObjData(card));
      const removeTypenameRelics = this.allData.relics.map((card: {}) => this.removeTypeNameObjData(card));
      this.allData = {
        cards: removeTypenameCards as [],
        relics: removeTypenameRelics as [],
      };
      this.allData = this.removeTypeNameObjData(this.allData);
      return JSON.stringify(this.allData, undefined, 3);
    },
    cardsOutput(): string {
      return JSON.stringify(this.allData.cards, undefined, 3);
    },
    relicsOutput(): string {
      return JSON.stringify(this.allData.relics, undefined, 3);
    },
    prerenderRoutesDifferent(): boolean {
      if (this.prerenderroutes.length === 0) {
        return false;
      }
      if (JSON.stringify(this.prerenderroutes) === JSON.stringify(this.prerenderCurrentRoutes)) {
        return false;
      }
      return true;
    },
    prerenderroutes(): string[] {
      if (this.isObjEmpty(this.allData)) {
        return [];
      }

      let routes: string[] = [];
      routes = routes.concat(this.baseRoutes);
      // get all cards
      this.allData.cards.forEach((item: {name: string}) => {
        /* tslint:disable-next-line:max-line-length */
        routes.push(`/tools/slaythespire/cards/${this.itemPureName(item.name)}/`);
      });

      this.allData.relics.forEach((item: {name: string}) => {
        /* tslint:disable-next-line:max-line-length */
        routes.push(`/tools/slaythespire/relics/${this.itemPureName(item.name)}/`);
      });
      // Sort urls        // consider any external urls
      // this.prerenderOutput = JSON.stringify(routes.sort());
      // // Compare current compile with live route render list
      // this.prerenderIdentical = this.prerenderRoutesIdentical(this.prerenderOutput, JSON.stringify(this.prerenderCurrentRoutes));
      return routes;
    },
    prerenderRoutesOutput(): string {
      if (this.isObjEmpty(this.allData)) {
        return '';
      }
      return JSON.stringify(this.prerenderroutes, undefined, 2);
    },
  },
  methods: {
    // Compare arrays
    // prerenderRoutesIdentical(a: any, b: any): boolean {
    //   if (a === b) {
    //     return true;
    //   }
    //   if (a == null || b == null) {
    //     return false;
    //   }
    //   if (a.length !== b.length) {
    //     return false;
    //   }

    //   // If you don't care about the order of the elements inside
    //   // the array, you should sort both arrays here.

    //   for (let i = 0; i < a.length; ++i) {
    //     if (a[i] !== b[i]) {
    //       return false;
    //     }
    //   }
    //   return true;
    // },
    removeTypeNameObjData(obj: any) {
      delete obj.__typename;
      return obj;
    },
    itemPureName(name: string) {
      return name.replace(/\s/g, '').replace(/\'/g, '').replace(/\-/g, '').toLowerCase();
    },
    isObjEmpty(object: any): boolean {
      return JSON.stringify(object) === JSON.stringify({});
    },
    async getFrontendData() {
      const res = await this.$apollo.query({
        query: gql`query {
          spirespyGetFrontendData {
            cards {
              name
              character
              type
              tags
              description
              descriptionplus
              mana
              manaplus
              rarity
              statement
              index
              supersynergies
              synergies
              antisynergies
              tier
              ratios
              beta
            }
            relics {
              name
              character
              rarity
              description
              tags
              tierironclad
              tiersilent
              tierdefect
              tierwatcher
              supersynergies
              synergies
              antisynergies
              statement
              ratios
              index
              beta
            }
          }
        }`,
      });
      console.log(res);
      this.allData = res.data.spirespyGetFrontendData;
    }
  }
});

